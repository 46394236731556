import React from "react"
import News from "../../blocks/news"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "osipov_about_liepaja" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to="/players/osipov">Артём Осипов</Link> дал комментарий касательно дела против ФК «Лиепая»:</p>
          <p>В 2017 году в один момент мне сообщили, что со мной хотят разорвать контракт. Запретили тренироваться с командой, перестали выделять деньги на аренду квартиры и платить зарплату. Таким образом я остался на улице и вынужден был вернуться в Ригу. После этого клуб обвинил меня в том, что я необоснованно покинул расположение команды, и разорвал со мной контракт в одностороннем порядке якобы по моей вине. Через какое то время, когда я уже играл в другой стране, меня публично поливали грязью в прессе.</p>
          <p>Спустя два года, 9 декабря, ФИФА вынесла решение, что клуб не имел права так себя вести, нарушил футбольный закон и обязан за это понести наказание в виде финансовой компенсации. Так и должно быть в цивилизованном мире. К сожалению, некоторые футболисты ещё не верят в силу законов ФИФА, а я на своём примере понял, что они работают, и там можно бороться с несправедливостью. Спасибо людям, которые встали у меня за спиной и прошли этот путь со мной, хоть это было и не легко. Компания Catenaccio показала, что не будет заигрывать с клубными боссами, а будет отстаивать интересы в первую очередь футболиста, что и должен делать профессиональный футбольный агент.</p>
        </div>
      </div>
    )
  }
}